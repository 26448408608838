<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="1000px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      @keydown.native.enter.exact.prevent.stop
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" style="padding-top: 10px">
        <el-col :span="24">
          <el-form-item
            prop="comment_node.data.node_comment"
            :label="__('Comment')"
          >
            <el-input
              type="textarea"
              v-model="nodeToBind.comment_node.data.node_comment"
              :autosize="{ minRows: 5, maxRows: 10 }"
              maxlength="2000"
              show-word-limit
              style="word-break: break-word"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";

export default {
  mixins: [BaseNode],
  components: {},
  data() {
    return {
      rules: {
        comment_node: {
          data: {
            node_comment: {
              required: true,
              message: __("Comment cannot be empty")
            }
          }
        }
      },
      activeTab: "comment"
    };
  },
  computed: {},
  methods: {
    cleanUpNodeToPrepareForSubmit() {
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  },
  created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.comment_node)) {
      this.$set(this.nodeToBind, "comment_node", {});
      this.$set(this.nodeToBind.comment_node, "data", {});
      this.$set(this.nodeToBind.comment_node.data, "node_comment", "");

      this.$set(this.nodeToBind, "node_type", NODE_TYPES.COMMENT.NODE_TYPE);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";

.el-form-item:last-of-type {
  margin-bottom: 10px;
}

.text-input ::v-deep textarea {
  resize: none;
}

.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}
</style>
